import { IonButton, IonCol, IonGrid, IonList, IonRow, IonSpinner } from '@ionic/react'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import SubscriberInfo from './SubscriptionInfo'

const styles = {
  card: {
    borderRadius: 10,
    padding: 20,
    margin: 16,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
  },
}

export default function Subscription({ userId }) {
  const [subscription, setSubscription] = useState({ data: null, loading: false, error: null })
  const [isSubscribed, setIsSubscribed] = useState(false)
  const windowLocation = useLocation()

  const getCustomerSubscription = async () => {
    if (userId) {
      try {
        let response = await axios.get(`/subscription/byCustomerId?id=${userId}`)
        setSubscription({ data: response.data, loading: false, error: null })
        setIsSubscribed(response?.data?.isSubscribed || false)
      } catch (error) {
        setSubscription({
          data: null,
          loading: false,
          error: 'There was an issue getting your data',
        })
      }
    }
  }

  useEffect(() => {
    if (windowLocation.pathname == '/account') {
      getCustomerSubscription()
    }
  }, [windowLocation])
  console.log(subscription, 'subscription')
  if (!isSubscribed) {
    return <NoSubscription />
  }
  return (
    <>
      <h6 style={{ marginLeft: 16 }}>Subscription</h6>
      <IonList mode='ios' inset={true}>
        <div style={{ paddingLeft: 18, paddingTop: 6, paddingBottom: 6 }}>
          <SubscriberInfo subscription={subscription} />
        </div>
      </IonList>
    </>
  )
}

function NoSubscription({}) {
  const slideOpts = {
    initialSlide: 1,
    speed: 400,
  }

  return (
    <div>
      <h5 style={{ marginTop: 20, marginLeft: 18, marginBottom: -8 }}>Subscription</h5>
      <div style={styles.card}>
        <div>You are not signed up for a package</div>
        <br></br>
        <div>Get Unlimited Haircuts starting at $59/mo</div>
        <IonButton
          routerDirection='none'
          routerLink='/subscription'
          fill='outline'
          style={{ marginTop: 20 }}
        >
          Packages
        </IonButton>
      </div>
    </div>
    // <div style={{height:400}}>

    //         <IonSlides pager={true} options={slideOpts}>
    //             <IonSlide style={{backgroundColor:'red', height:400}}>
    //                 Slide1
    //             </IonSlide>
    //             <IonSlide style={{backgroundColor:'blue', height:400}}>
    //                 <h1>Slide 2</h1>
    //             </IonSlide>
    //             <IonSlide style={{backgroundColor:'green', height:400}}>
    //                 <h1>Slide 3</h1>
    //             </IonSlide>
    //         </IonSlides>

    // </div>
  )
}
